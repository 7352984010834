<template>
  <div class="contact-grid">
        <div class="contact-line" v-for="item in cData" :key="item">
            <img :src="item.img" :alt="item.alt">
            <a :href="item.url" target="_blank">
            <p>{{item.txt}}</p></a>
        </div>
  </div>
</template>

<script>
import cJSON from "/contacts.json"
export default {
data() {
    return {
        cData: cJSON.contactList
    }
}
}
</script>

<style scoped>
    .contact-grid {
        display: grid;
        grid-template-columns: 270px;
        grid-template-rows: max-content;
        grid-auto-rows: max-content;
    }

    .contact-line {
        display:flex;
        flex-direction: row;
        padding-left: 10%; 
        align-items: center;
    }
    .contact-line a{
        text-decoration: none; 
        color: #2c3e50;
    }
    .contact-line img {
        padding-right: 20px;
        width: 10px; 
    }
</style>