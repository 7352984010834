<template>
  <div class="main-container" >
    <l-nav-pannel class="l-container" v-if="!isMobile()"/>
    <div class="overlap">
      <back-patterns class="back"/>
      <main-grid class="front mid-container"/>
    </div>
    <r-nav-pannel class="r-container" v-if="!isMobile()"/>
  </div>


</template>

<script>
import MainGrid from './components/MainGrid.vue';
import LNavPannel from './components/LNavPannel.vue';
import RNavPannel from './components/RNavPannel.vue';
import BackPatterns from './components/BackPatterns.vue';

export default {
  name: 'App',
  components: {
    MainGrid,
    LNavPannel,
    RNavPannel,
    BackPatterns
  },
  methods: {
    isMobile() {
        if( screen.width <= 760 ) {
            return true;
        }
        else {
            return false;
        }
    }
  }
}
</script>

<style>
@font-face {
    font-family: Monserrat;
    src: url('~@/assets/fonts/Montserrat/Montserrat-VariableFont_wght.ttf');
}
/* Font settongs https://fonts.google.com/selection/embed */

#app {
  font-family: Monserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
}
#app h1 {
  color: #474866;
  font-size: 50px;
  margin-top: 10px;
  margin-bottom: 0px;
}
#app h2 {
  color: #474866;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  margin: 8px;
}
#app h3 {
  color: #474866;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  margin: 4px;
}
#app p {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  margin: 1px;
}

.cell {
  background-color: #DEE8EE;
  border: #DEE8EE;
  margin: 10px;
  border-radius: 15px;
  box-shadow: inset 0 0 18px 10px rgb(182, 182, 182);
}

.main-container {
  margin: 0px;
  display: flex;
  justify-content: center;
  width: 100%;
  
}

.l-container {
  width: 10%;
}
.r-container {
  width: 10%;
}

.overlap {
  width: 100%;
  height: 46em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "overlap";
  justify-items: center;
}
.front {
  grid-area: overlap;
  z-index: 2;
  width: 100%;
}
.back {
  grid-area: overlap;
  z-index: 1;
  width: 97%;
}
</style>
