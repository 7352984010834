<template>
    <div class="main-container">
        <div class="main-grid">
            <div class="cell c-name">
                <div class="header">
                    <h1>Pan Philip</h1>
                    <p>Web Developer</p>
                </div>
            </div>

            <div class="cell c-photo">
                <img src="..\assets\images\picture.png" alt="photo">
            </div>

            <div class="cell c-contact">
                <img src="..\assets\images\contact\contact info.png" alt="contact information">
                <contact-info/>
            </div>

            <div class="cell c-education">
                <img src="..\assets\images\education.png" alt="education">
                <h2>∙ MOSCOW COLLEGE OF ENTREPRENEURSHIP</h2>
                <h3>Associate degree in information technologies<br>GPA: 3.9 / 4.0</h3>
                <p class="year">2018-2021</p>
                <h2>∙ MOSCOW POLYTECHNIC UNIVERSITY</h2>
                <h3>Unfinished bachelor's degree in <br>cyber-physical systems</h3>
                <p class="year">2021-2022</p>
            </div>

            <div class="cell c-opentowork">
                <a  href="https://www.linkedin.com/in/filipp-pan-675b432b3/" target="_blank">
                    <img src="..\assets\images\open.png" alt="open to work">
                </a>
            </div>

            <div class="cell c-see-projects">
                <h2 class="font-italic">SEE MY<br>PROJECTS</h2>
                <button id="btn-down-arrow">
                    <img src="..\assets\images\Double Down.png" alt="see projects">
                </button>
            </div>
        </div>

        <work-experience/>

    </div>
</template>

<script>
import ContactInfo from './ContactInfo.vue'
import WorkExperience from './WorkExperience.vue'

import jsonData from '/experience.json'
export default {
  components: { WorkExperience, ContactInfo },
    data() {
        return {
            workData: jsonData.JobList
        }
    }
}
</script>

<style scoped>
    .main-container {
        display: flex;
        flex-direction: row;
        justify-content: center;  
        align-items: top; 
        flex-wrap: wrap;

    }

    .main-grid {
        display: grid;
        grid-template-rows: 90px 180px 180px 180px ;
        grid-template-columns: 200px 200px 200px;
        height: fit-content;
        margin-top: 30px;
    }

    .c-name {
        grid-area: 1 / 1 / 1 / 4;
        align-self: flex-end;
        height: 100%;

        background-image: url('../assets/images/Custom grid.png');
        background-position-y: top;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .header {
        display: flex;
        align-items: flex-end;
        justify-content: space-around;
    }
    .header p{
        margin-bottom: 13px !important;
    }

    .c-photo {
        grid-area: 2 / 1 / 2 / 1;
    }
    .c-photo img {
        width: 100%;
        height: 102%;
    }

    .c-contact {
        grid-area: 2 / 2 / 2 / 4;
    }
    .c-contact img{
        width: 100%;
    }

    .c-education {
        grid-area: 3 / 1 / 5 / 3;
        text-align: left;
    }
    .c-education img{
        width: 100%;
    }
    .c-education h2 {
        padding-left: 20px;
        padding-top: 15px
    }
    .c-education h3 {
        padding-left: 15px;
    }
    .c-education p {
        text-align: right;
        padding-right: 20px;
    }

    .c-opentowork {
        grid-area: 3 / 3 / 3 / 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        box-shadow: inset 0 0 29px 1px #5AAE07 !important;
    }
    .c-opentowork a {
        width: 100%;
        height: 100%;
    }
    .c-opentowork a img {  
        padding: 24px;
        width: 78%;
    }

    .c-see-projects {
        grid-area: 4 / 3 / 4 / 3;
        background-image: url('../assets/images/down blured.png');
        background-position-y: top;
        background-size: contain;
        background-repeat: no-repeat;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    .c-see-projects button {
        width: 40px;
        height: 40px;
        border: 5px;
        display: contents;
    }
    .c-see-projects button img {
        width: 40px;
    }


    .font-italic {
        font-family: "Montserrat", sans-serif;
        font-optical-sizing: auto;
        font-weight: 850 !important;
        font-style: italic !important;
        font-size: 28px !important;
    }
</style>