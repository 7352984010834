<template>
<div class="cards-container">
            <div class="cards">
                <div class="card-generate" v-for="item in workData" :key='item'>
                    <div class="cell c-work-ex">
                        <div class="name-year">
                            <a :href="item.Url" target="_blank">
                            <h2>{{ item.JobName }}</h2></a>
                            <p>{{ item.Year }}</p>
                        </div>
                        <h3>{{ item.Role }}</h3>
                        <ul>
                            <li v-for="tsk in item.Tasks" :key='tsk'>
                                <h3 class="li-h3">{{ tsk.Task }}</h3>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import jsonData from '/experience.json'
export default {
    data() {
        return {
            workData: jsonData.JobList
        }
    }
}
</script>

<style>
    .cards {
        margin-top: 120px;
        display: grid;
        grid-template-columns: 400px;
        grid-template-rows: 180px;
        grid-auto-rows: 180px;
    }
    
    .cards-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        isolation: isolate;
    }
    .cards-container::before {
        content: "";
        position: absolute;
        left: 7px;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-image: url('../assets/images/work experience.png');
        background-position-y: top;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.3;
    }
    .c-work-ex {
        width: 380px;
        height: 160px;
        text-align: left;
        
    }
    .c-work-ex h2 {
        padding-left: 18px;
        padding-top: 15px
    }
    .c-work-ex h3 {
        padding-left: 32px;
    }

    .name-year {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }
    .name-year a{
        text-decoration: none;
    }
    .c-work-ex p {
        padding-bottom: 9px;
        padding-right: 20px;
    }

    .c-work-ex ul {
        list-style-type: '-';
        padding-left: 42px;
        margin-top: 6px
    }
    .li-h3 {
        padding-left: 10px !important;
    }
</style>