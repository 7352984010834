<template>
  <div class="container">
    <div class="top">
      <img src="..\assets\images\patterns\Pattern-TL.png" alt="" class="TL">
      <img src="..\assets\images\patterns\Pattern1-TR.png" alt="" class="TR">
    </div>
    <div class="mid">
      <img src="..\assets\images\patterns\Pattern-M.png" alt="" class="M">
    </div>
    <div class="bottom">
      <img src="..\assets\images\patterns\Pattern2-BL.png" alt="" class="BL">
      <img src="..\assets\images\patterns\Pattern-BR.png" alt="" class="BR">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.top {
  display: flex;
  justify-content: space-between;
}

.bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.TL {
  width: 88px;
  height: 84px;
}
.TR {
  width: 651px;
  height: 150px;
}

.M {
  width: 335px;
  margin-left: 50px;
}

.BL {
  width: 700px;
  height: 200px;
}
.BR {
  width: 90px;
}
</style>