<template>
  <div class="main-container">LEFT</div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .main-container {
        height: 100%;
        width: 20%;
        background-color: #25274D;
    }
</style>